import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
    PageHeader,
    TechnologyPartners,
    BrandsWorkedWithUs
} from '@components';
import {
    BetterThanOther,
    MultiColumnList,
    ServiceCatalogues
} from '@components/industries';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';

class Travel extends Component {
    render() {
        const travel = pageContent.subPage.travel;
        return (
            <Layout>
                <PageHeader image={travel.headerImage} />

                <Heading
                    title={travel.title}
                    overview={travel.overview}
                    noHeadingIcon={true} />

                <BetterThanOther content={travel.betterThanOther} />

                <BetterThanOther content={travel.betterServices} titleNoHighlight={true} hasBackground={true} />

                <MultiColumnList listStyle={'check'} content={travel.solutions} />

                <MultiColumnList content={travel.functions} />

                <PageNavigation
                    prev={pageContent.navigation.prev}
                    next={pageContent.navigation.next} />

                <TechnologyPartners />

                <BrandsWorkedWithUs />

            </Layout>
        );
    }
}

export default Travel;
